/*****************************************/
/* Global Theming for Harmony ************/
/*****************************************/
:root {
  --color-primary: #009BA7;
  --color-secondary: #001A67;
  --color-tertiary: #D93EAC;
  --color-accent-sand: #EEEDE8;
  --color-accent-grey: #8A8A8A;
  --color-accent-blue-grey: #5569A0;
  --color-accent-green: #5FC787;
  --color-accent-yellow: #FFCC21;
  --color-accent-coral: #FF6E47;

  --color-module-color-pricing: #64bb58;
  --color-module-color-network: #009BA7;
  --color-module-color-news: #c79233;
  --color-module-color-annual-review: #b5abe0;
  --color-module-color-hub: #c96868;
  --color-module-color-events: #c0699e;
  --color-module-color-services: #ef8463;
  --color-module-color-hse: #5082c6;
  --color-module-color-hqms: #dcca50;
  --color-module-color-admin: #8d8d8d;
}

textarea:hover,
textarea:active,
textarea:focus,
input[type='text']:hover,
input[type='text']:active,
input[type='text']:focus,
button:focus,
button:active,
button:hover,
label:focus,
button,
.btn:active,
.btn.active {
  outline: 0 !important;
  -webkit-appearance: none !important;
  box-shadow: none !important;
}

.form-select:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.form-control-xs {
  height: 22px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5; /* If Placeholder of the input is moved up, rem/modify this. */
  border-radius: 3px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled,
button:disabled {
  cursor: not-allowed;
}

* {
  font-family: 'Open Sans', sans-serif !important;
}

h1, h2, h3, h4, h5, h6, p, b, i, small, div, a, button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, a, button {
  font-family: 'Poppins', sans-serif !important;
}

p, b, i, div {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif !important;
}

.pointer {
  cursor: pointer;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-2xl .modal-dialog {
  max-width: calc(100vw - 350px);
}

.modal-3xl .modal-dialog {
  max-width: calc(100vw - 100px);
}

.modal-title {
  color: var(--color-accent-grey) !important;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
}

a {
  text-decoration: none;
}

/////////////////////////
///// HARMONY BLUE //////
/////////////////////////
.harmony-primary-background {
  background-color: var(--color-primary) !important;
}

.harmony-primary-text {
  color: var(--color-primary) !important;
}

.harmony-primary-button {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.harmony-primary-outline-button {
  background-color: transparent !important;
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.harmony-primary-outline-button:hover {
  color: #ffffff !important;
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.harmony-primary-link {
  text-decoration: none !important;
  color: var(--color-primary) !important;
  cursor: pointer;
}

.harmony-primary-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-grey) !important;
  cursor: pointer;
}

.harmony-primary-label {
  color: white !important;
  background-color: var(--color-primary) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

//////////////////////////////
///// HARMONY Secondary //////
//////////////////////////////
.harmony-secondary-text {
  color: var(--color-secondary) !important;
}

.harmony-secondary-background {
  background-color: var(--color-secondary) !important;
}

.harmony-secondary-button {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.harmony-secondary-outline-button {
  background-color: transparent !important;
  color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.harmony-secondary-outline-button:hover {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}

.harmony-secondary-label {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-secondary) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

.harmony-divider-blue {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-secondary);
}

.harmony-secondary-link {
  text-decoration: none !important;
  color: var(--color-secondary) !important;
  cursor: pointer;
}

.harmony-secondary-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-grey) !important;
  cursor: pointer;
}

/////////////////////////
//// HARMONY PURPLE /////
/////////////////////////
.harmony-tertiary-text {
  color: var(--color-tertiary) !important;
}

.harmony-tertiary-background {
  background-color: var(--color-tertiary) !important;
}

.harmony-tertiary-button {
  background-color: var(--color-tertiary) !important;
  border-color: var(--color-tertiary) !important;
}

.harmony-tertiary-outline-button {
  background-color: transparent !important;
  color: var(--color-tertiary) !important;
  border-color: var(--color-tertiary) !important;
}

.harmony-tertiary-outline-button:hover {
  color: #ffffff !important;
  background-color: var(--color-tertiary) !important;
  border-color: var(--color-tertiary) !important;
}

.harmony-tertiary-link {
  text-decoration: none !important;
  color: var(--color-tertiary) !important;
  cursor: pointer;
}

.harmony-tertiary-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-grey) !important;
  cursor: pointer;
}

.harmony-tertiary-label {
  color: white !important;
  background-color: var(--color-tertiary) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

/////////////////////////
///// HARMONY SAND //////
/////////////////////////
.harmony-accent-sand-text {
  color: var(--color-accent-sand) !important;
}

.harmony-accent-sand-background {
  background-color: var(--color-accent-sand) !important;
}

.harmony-accent-sand-button {
  background-color: var(--color-accent-sand) !important;
  border-color: var(--color-accent-sand) !important;
}

.harmony-accent-sand-outline-button {
  background-color: transparent !important;
  color: var(--color-accent-sand) !important;
  border-color: var(--color-accent-sand) !important;
}

.harmony-accent-sand-outline-button:hover {
  color: var(--color-secondary) !important;
  background-color: var(--color-accent-sand) !important;
  border-color: var(--color-accent-sand) !important;
}

.harmony-accent-sand-label {
  color: var(--color-secondary) !important;
  background-color: var(--color-accent-sand) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

.harmony-divider-sand {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-accent-sand);
}


.harmony-accent-sand-link {
  text-decoration: none !important;
  color: var(--color-accent-sand) !important;
  cursor: pointer;
}

.harmony-accent-sand-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-coral) !important;
  cursor: pointer;
}

/////////////////////////
///// HARMONY GREY //////
/////////////////////////
.harmony-accent-grey-text {
  color: var(--color-accent-grey) !important;
}

.harmony-accent-grey-background {
  background-color: var(--color-accent-grey) !important;
}

.harmony-accent-grey-button {
  background-color: var(--color-accent-grey) !important;
  border-color: var(--color-accent-grey) !important;
}

.harmony-accent-grey-outline-button {
  background-color: transparent !important;
  color: var(--color-accent-grey) !important;
  border-color: var(--color-accent-grey) !important;
}

.harmony-accent-grey-outline-button:hover {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-grey) !important;
  border-color: var(--color-accent-grey) !important;
}

.harmony-accent-grey-link {
  text-decoration: none !important;
  color: var(--color-accent-grey) !important;
  cursor: pointer;
}

.harmony-accent-grey-link:hover {
  text-decoration: underline !important;
  color: var(--color-secondary) !important;
  cursor: pointer;
}

.harmony-accent-grey-label {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-grey) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

.harmony-divider-grey {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-accent-grey);
}

//////////////////////////////
///// HARMONY Blue Grey //////
//////////////////////////////
.harmony-accent-blue-grey-text {
  color: var(--color-accent-blue-grey) !important;
}

.harmony-accent-blue-grey-background {
  background-color: var(--color-accent-blue-grey) !important;
}

.harmony-accent-blue-grey-button {
  background-color: var(--color-accent-blue-grey) !important;
  border-color: var(--color-accent-blue-grey) !important;
}

.harmony-accent-blue-grey-outline-button {
  background-color: transparent !important;
  color: var(--color-accent-blue-grey) !important;
  border-color: var(--color-accent-blue-grey) !important;
}

.harmony-accent-blue-grey-outline-button:hover {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-blue-grey) !important;
  border-color: var(--color-accent-blue-grey) !important;
}

.harmony-accent-blue-grey-link {
  text-decoration: none !important;
  color: var(--color-accent-blue-grey) !important;
  cursor: pointer;
}

.harmony-accent-blue-grey-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-grey) !important;
  cursor: pointer;
}

.harmony-accent-blue-grey-label {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-blue-grey) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

.harmony-divider-blue-grey {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-accent-blue-grey);
}

//////////////////////////
///// HARMONY Green //////
//////////////////////////
.harmony-accent-green-text {
  color: var(--color-accent-green) !important;
}

.harmony-accent-green-background {
  background-color: var(--color-accent-green) !important;
}

.harmony-accent-green-button {
  background-color: var(--color-accent-green) !important;
  border-color: var(--color-accent-green) !important;
}

.harmony-accent-green-outline-button {
  background-color: transparent !important;
  color: var(--color-accent-green) !important;
  border-color: var(--color-accent-green) !important;
}

.harmony-accent-green-outline-button:hover {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-green) !important;
  border-color: var(--color-accent-green) !important;
}

.harmony-accent-green-link {
  text-decoration: none !important;
  color: var(--color-accent-green) !important;
  cursor: pointer;
}

.harmony-accent-green-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-blue-grey) !important;
  cursor: pointer;
}

.harmony-accent-green-label {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-green) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

.harmony-divider-green {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-accent-green);
}

/////////////////////////
//// HARMONY YELLOW /////
/////////////////////////
.harmony-accent-yellow-text {
  color: var(--color-accent-yellow) !important;
}

.harmony-accent-yellow-button {
  background-color: var(--color-accent-yellow) !important;
  border-color: var(--color-accent-yellow) !important;
}

.harmony-accent-yellow-outline-button {
  background-color: transparent !important;
  color: var(--color-accent-yellow) !important;
  border-color: var(--color-accent-yellow) !important;
}

.harmony-accent-yellow-outline-button:hover {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-yellow) !important;
  border-color: var(--color-accent-yellow) !important;
}

.harmony-accent-yellow-link {
  text-decoration: none !important;
  color: var(--color-accent-yellow) !important;
  cursor: pointer;
}

.harmony-accent-yellow-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-grey) !important;
  cursor: pointer;
}

.harmony-accent-yellow-label {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-yellow) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

.harmony-divider-yellow {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-accent-yellow);
}

//////////////////////////
///// HARMONY Coral //////
//////////////////////////
.harmony-accent-coral-text {
  color: var(--color-accent-coral) !important;
}

.harmony-accent-coral-background {
  background-color: var(--color-accent-coral) !important;
}

.harmony-accent-coral-button {
  background-color: var(--color-accent-coral) !important;
  border-color: var(--color-accent-coral) !important;
}

.harmony-accent-coral-outline-button {
  background-color: transparent !important;
  color: var(--color-accent-coral) !important;
  border-color: var(--color-accent-coral) !important;
}

.harmony-accent-coral-outline-button:hover {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-coral) !important;
  border-color: var(--color-accent-coral) !important;
}

.harmony-accent-coral-link {
  text-decoration: none !important;
  color: var(--color-accent-coral) !important;
  cursor: pointer;
}

.harmony-accent-coral-link:hover {
  text-decoration: underline !important;
  color: var(--color-accent-blue-grey) !important;
  cursor: pointer;
}

.harmony-accent-coral-label {
  color: var(--color-accent-sand) !important;
  background-color: var(--color-accent-coral) !important;
  border-radius: 5px;
  padding: 0.15em 0.35em;
}

.harmony-divider-coral {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--color-accent-coral);
}

/////////////////////////
/// Custom Scrollbar ////
/////////////////////////
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-accent-sand);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-accent-grey);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/////////////////////////
//////// Loader /////////
/////////////////////////
.overlay-loader {
  position: fixed;
  z-index: 1006;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
}

/////////////////////////
///////// OTHER /////////
/////////////////////////
.show-password {
  position: absolute;
  right: 1em;
  top: 0.5em;
  padding: 0.5em;
  cursor: pointer;
  z-index: 1003;
}

.rounded {
  border-radius: 27.5px;
}

.nav-link {
  color: var(--color-primary);
}

.nav-link.active {
  color: var(--color-tertiary);
}

.user-picture {
  width: 90%;
  max-width: 90%;
  display: inline;
  border-radius: 0.5em;
  margin-right: 1em;
}

.portal-component-header {
  color: var(--color-accent-grey);
  font-weight: 600;
}

//////////////////
/// Validation ///
//////////////////
.required {
  border: 1px solid var(--color-tertiary);
}

.required-checkbox {
  background-color: rgba(162, 43, 157, 0.1);
  border-radius: 5px;
}

.required-text {
  color: var(--color-tertiary) !important;
  font-weight: bold;
}

///////////////////
/// Date Button ///
///////////////////
.input-picker-value {
  display: inline-block;
  width: 85% !important;
  float: left;
}

.input-picker-button {
  display: inline-block;
  float: right;
}

////////////////////////////////
/// Bootstrap Switch/Toggle ///
//////////////////////////////
.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

@media print {
  * {
    print-color-adjust: exact;
  }
}
